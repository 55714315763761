import { trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ICalculationLink } from '@app/models/ICalculationLink';
import { AuthService } from '@app/services/auth/auth.service';
import { PendingUserService } from '@app/services/pending-user/pending-user.service';
import { removeUserData } from '@app/store/actions/app/app.actions';
import { IAppState } from '@app/store/reducers/app/app.reducer';
import { navbarAnimation } from '@app/utils/animations/animations';
import { LOCAL_STORAGE_USER_KEY, URLS_WITHOUT_DUE_WARNING, URLS_WITHOUT_NAVBAR_OPTIONS } from '@app/utils/constants';
import { LocalStorage } from '@app/utils/local-storage/local-storage.utils';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
  animations: [ trigger('navbarAnimation', navbarAnimation ) ]
})
export class NavbarComponent {
  @Input() showOptions = true;

  selectedMenu = '';

  isLoading$: Observable<boolean> = this.store.select(
    (state) => state.app.isLoading
  );

  user$: Observable<any> = this.store.select(
    (state) => state.app.user
  );

  isSuscribed$: Observable<boolean> = this.store.select(
    (state) => state.app.user.is_suscribed
  );

  isLogged$: Observable<boolean> = this.store.select(
    (state) => state.app.isLogged
  );

  calculationLink$: Observable<ICalculationLink[]> = this.store.select(
    (state) => state.app.calculationlinks
  );

  dolarBlue$: Observable<number> = this.store.select(
    (state) => state.app.dolarBlue
  );

  showMobileNavbar = false;
  showDueWarning = false;
  viewCanShowDueWarning = true;
  isHomepage = false;
  isMarketing = false;
  isMobile = false;
  daysDiff = 0;
  isPendingUser = false;
  suscribedUser = false;

  constructor(
    private store: Store<{ app: IAppState }>,
    private authService: AuthService,
    private router: Router,
    private deviceDetectorService: DeviceDetectorService,
    private pendingUserService: PendingUserService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.user$.subscribe(user => {
      if(user && !!user.is_suscribed){
        this.suscribedUser = true;
      } 
    })

    this.pendingUserService.pendingUserSubject.subscribe({
      next: (value) => {
        this.isPendingUser = value;
      }
    })
    this.isMobile = this.deviceDetectorService.isMobile()

    this.router.events.subscribe((event) =>{
      if(event instanceof NavigationEnd){
        this.isHomepage = event.url === '/';
        this.isMarketing = event.url === '/suscribirse'
        this.viewCanShowDueWarning = !URLS_WITHOUT_DUE_WARNING.some(url => event.url.includes(url))
      }
    })

    this.user$.subscribe((user) => {
      if (user && user.plan_due && !!user.is_suscribed) {
        const planDueDate = new Date(user.plan_due);
        const todayDate = new Date();

        const timeDiff = planDueDate.getTime() - todayDate.getTime();
        this.daysDiff = timeDiff / (1000 * 3600 * 24) + 1;
        if(this.daysDiff < 4){
          this.showDueWarning = true;
        }
      }
    });
  }

  logout() {
    this.authService.logout().subscribe(() => {
      LocalStorage.remove(LOCAL_STORAGE_USER_KEY)
      this.store.dispatch(removeUserData())
      this.router.navigate(['/']);
    });
    this.resetMenu();
  }

  selectMenu(menu) {
    this.selectedMenu = menu === this.selectedMenu ? "" : menu;
  }

  toggleMobileNavbar() {
    this.showMobileNavbar = !this.showMobileNavbar;
  }

  showNavbar() {
    return URLS_WITHOUT_NAVBAR_OPTIONS.some(url => this.router.url.includes(url))
  }

  resetMenu() {
    this.selectedMenu = '';
    this.showMobileNavbar = false;
  }

}
