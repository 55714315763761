import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  apiURL = environment.endpointBackend;

  constructor(
    public httpClient: HttpClient
  ) {}

  httpGet(path: string, options = {}): Observable<any> {
    return this.httpClient.get(this.getUrl(path), options)
  }

  httpPost(path: string, body = {}, options = {}): Observable<any> {
    return this.httpClient.post(
      this.getUrl(path),
      body,
      options
    )
  }

  httpPut(path: string, body = {}): Observable<any> {
    return this.httpClient.put(
      this.getUrl(path),
      body
    )
  }

  httpDelete(path: string, body = {}): Observable<any> {
    return this.httpClient.delete(
      this.getUrl(path),
      body
    )
  }

  http(method, path, options) {
    return this.httpClient[method](this.getUrl(path), options);
  }

  private getUrl(path: string): string {
    return `${this.apiURL}/${path}`;
  }
}
