import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from '../base/base.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseService {
    prefixPath = 'auth';
    resetUserSubject = new Subject<any>();

    constructor(httpClient: HttpClient) {
      super(httpClient);
    }

    login(data): Observable<any> {
      return this.httpPost(`${this.prefixPath}/login`, data)
    }

    generateToken(data): Observable<any> {
      return this.httpPost(`${this.prefixPath}/generate-token`, data)
    }

    register(data): Observable<any> {
      return this.httpPost(`${this.prefixPath}/register`,data)
    }

    logout(): Observable<any> {
      return this.httpPost(`${this.prefixPath}/logout`);
    }

    getUser(): Observable<any> {
      return this.httpGet(`${this.prefixPath}/get-user`);
    }

    existEmail(email): Observable<any> {
      return this.httpPost(`${this.prefixPath}/exist-email`, { email })
    }

    forgotPassword(email): Observable<any> {
      return this.httpPost(`${this.prefixPath}/forgot`, { email })
    }

    resetPassword(data): Observable<any> {
      return this.httpPost(`${this.prefixPath}/reset`, data)
    }

    changePassword(data): Observable<any> {
      return this.httpPost(`${this.prefixPath}/change-password`, data)
    }
}