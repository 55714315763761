import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IUserLog } from '@app/models/IUserLog';
import { BaseService } from '../base/base.service';

@Injectable({providedIn: 'root'})
export class UserLogsService extends BaseService {
    prefixPath = 'log';

    constructor(httpClient: HttpClient) {
      super(httpClient);
    }

    sendLog(user_id, type, description = "N/A", status = "OK") {
        const log: IUserLog = { user_id, type, description, status}
        this.httpPost(`${this.prefixPath}`, log).subscribe();
    }
    
}