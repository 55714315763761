<!--<footer class="pre-footer is-not-printable">
    <div class="container">
        <div class="columns">
            <div class="column content is-narrow">
                <img class="pre-footer__logo" src="/assets/images/iso-sismat.png" alt="Sismat">
            </div>
            <div class="column content is-2">
                <p class="pre-footer__title">Acerca de nosotros</p>
                <p class="pre-footer__text">
                    SISMAT<br/>
                    Argentina<br/>
                    info@sismat.com.ar
                </p>
            </div>
            <div class="column content">
                <p class="pre-footer__title">Accesos Rapidos</p>
                <div class="columns">
                    <div class="column">
                        <p class="pre-footer__text">
                            Cómputo personalizado<br/>
                            Costos de mano de obra<br/>
                            Costos de materiales
                        </p>
                    </div>
                    <div class="column">
                        <p class="pre-footer__text">
                            Cómputo personalizado<br/>
                            Costos de mano de obra<br/>
                            Costos de materiales
                        </p>
                    </div>
                    <div class="column">
                        <p class="pre-footer__text">
                            Cómputo personalizado<br/>
                            Costos de mano de obra<br/>
                            Costos de materiales
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>-->
<footer class="post-footer has-background-info is-not-printable">
    <div class="container">
        <div class="columns">
            <div class="column">
                <p>2015 - {{ getCurrentYear }} - Sismat&copy;. Todos los derechos reservados</p>
            </div>
            <div class="column has-text-right-desktop">
                <p>
                    <span class="is-clickable" [routerLink]="['/terminos-y-condiciones']">Términos y condiciones</span> | 
                    <span class="is-clickable" [routerLink]="['/protección-de-datos-personales']">Protección de datos personales</span>
                </p>
            </div>
        </div>
    </div>
</footer>