import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPlan } from '@app/models/IPlan';
import { Observable } from 'rxjs';
import { BaseService } from '../base/base.service';

@Injectable({
    providedIn: 'root'
})
export class PlansService extends BaseService {
    prefixPath = 'plans';

    constructor(httpClient: HttpClient) {
      super(httpClient);
    }

    getData(): Observable<IPlan[]> {
      return this.httpGet(`${this.prefixPath}`)
    }
}