import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IComputeStage } from '@app/models/IComputeStage';
import { IStage } from '@app/models/IStage';
import { Observable } from 'rxjs';
import { BaseService } from '../base/base.service';

@Injectable({
    providedIn: 'root'
})
export class UserCalculationsService extends BaseService {
    prefixPath = 'usercalculations';

    constructor(httpClient: HttpClient) {
      super(httpClient);
    }

    getUserCalculations(userId): Observable<any> {
        return this.httpGet(`${this.prefixPath}/profile/${userId}`)
    }

    saveUserCalculation(data) {
        return this.httpPost(`${this.prefixPath}`, data)
    }

    updateUserCalculation(id, data) {
        return this.httpPut(`${this.prefixPath}/${id}`, data)
    }

    deleteUserCalculation(id) {
        return this.httpDelete(`${this.prefixPath}/${id}`);
    }
}