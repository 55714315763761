import { ICalculationLink } from '@app/models/ICalculationLink';
import { IError } from '@app/models/IError';
import { INotification } from '@app/models/INotification';
import { IPlan } from '@app/models/IPlan';
import { IUserCalculationSummary } from '@app/models/IUserCalculationSummary';
import { createAction, props } from '@ngrx/store';

export const startLoading = createAction(
  '[App] Start Loading'
);

export const stopLoading = createAction(
  '[App] Stop Loading'
);

export const storeUserData = createAction(
  '[App] Store User Data',
  props<{ user: any }>()
);

export const removeUserData = createAction(
  '[App] Remove User Data'
);

export const loadPlans = createAction(
  '[App] Load Plans'
);

export const loadPlansSuccess = createAction(
  '[App] Load Plans Success',
  props<{ plans: IPlan[] }>()
);

export const loadCalculationLinks = createAction(
  '[App] Load CalculationLinks'
);

export const loadCalculationLinksSuccess = createAction(
  '[App] Load CalculationLinks Success',
  props<{ calculationlinks: ICalculationLink[] }>()
);

export const loadUserCalculations = createAction(
  '[App] Load User Calculations',
  props<{ userId: string }>()
);

export const loadUserCalculationsSuccess = createAction(
  '[App] Load User Calculations Success',
  props<{ usercalculations: IUserCalculationSummary[] }>()
);

export const loadDolarBlue = createAction(
  '[App] Load Dolar Blue'
)

export const loadDolarBlueSuccess = createAction(
  '[App] Load Dolar Blue Success',
  props<{ dolarBlue: number }>()
)

export const getPreferenceIdPostRegistration = createAction(
  '[App] Get Preference Id Post Registration',
  props<{ userId: string, planId: string }>()
);

export const getPreferenceIdPostRegistrationSuccess = createAction(
  '[App] Get Preference Id Post Registration Success',
  props<{ mercadopagoData: any }>()
);

export const setNotification = createAction(
  '[App] Set Notification',
  props<{ notification: INotification }>()
);

export const resetNotification = createAction(
  '[App] Reset Notification'
);

export const setError = createAction(
  '[App] Set Error',
  props<{ error: IError }>()
);

export const resetError = createAction(
  '[App] Reset Error'
);