import { Component, OnInit } from '@angular/core';
import { IAppState } from '@app/store/reducers/app/app.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.sass']
})

export class NotFoundComponent implements OnInit {

    user$: Observable<any> = this.store.select(
      (state) => state.app.user
    );
  
    isSuscribed$: Observable<boolean> = this.store.select(
      (state) => state.app.user.is_suscribed
    );
  
    constructor(
        private store: Store<{ app: IAppState }>
    ) { }

    ngOnInit() { }
}