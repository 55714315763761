import { Injectable } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';


@Injectable({
    providedIn: 'root'
})
export class DataLayerService {
   private window; 

   constructor (private _windowRef: WindowReferenceService)
   {
       this.window = _windowRef.nativeWindow; // intialise the window to what we get from our window service
       this.pushDataLayerObject({
        event: 'pageLoad'
       });
   }

    private pushDataLayerObject(obj)
    {
        if(obj) this.window.dataLayer.push(obj);
    }
   
   
   //list of all our dataLayer methods

   logPageView(url)
   {
       const hit = {
           event: 'pageview',
           url
       };
       this.pushDataLayerObject(hit);
   }

   logEvent(event,category = null,action = null,label = null)
   {
       const hit = {
           event:event,
           ...(category ? { category } : null),
           ...(action ? { action } : null),
           ...(label ? { label } : null)
       }
        this.pushDataLayerObject(hit);
   }

   logCustomDimensionTest(value)
   {
       const hit = {
           event:'custom-dimension',
           value:value
       }
       this.pushDataLayerObject(hit);
   }

   // .. add more custom methods as needed by your app.
}