import { trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { INotification } from './models/INotification';
import { AuthService } from './services/auth/auth.service';
import { ThirdPartyService } from './services/third-party/third-party.service';
import { loadCalculationLinks, loadDolarBlue, loadPlans, resetNotification, storeUserData, setNotification } from './store/actions/app/app.actions';
import { IAppState } from './store/reducers/app/app.reducer';
import { inOutAnimation, onlyOutAnimation } from './utils/animations/animations';
import { LOCAL_STORAGE_USER_KEY, URLS_WITHOUT_FOOTER, URLS_WITHOUT_NAVBAR_OPTIONS } from './utils/constants';
import { LocalStorage } from './utils/local-storage/local-storage.utils';
import { DataLayerService } from './services/window/data-layer.service';
import { UserLogsService } from './services/userlogs/userlogs.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  animations: [
    trigger('onlyOutAnimation', onlyOutAnimation),
    trigger('inOutAnimation', inOutAnimation)
  ]
})

export class AppComponent implements OnInit {
  showAuthModal = false;
  title = 'sismat-frontend-app';
  userCheckReady = false;
  loggedOutWarnings = 0;

  urlsWithoutFooter = URLS_WITHOUT_FOOTER;
  urlsWithoutNavbarOptions = URLS_WITHOUT_NAVBAR_OPTIONS;

  mercadopagoData$: Observable<string> = this.store.select(
    (state) => state.app.mercadopagoData
  );

  notification$: Observable<INotification> = this.store.select(
    (state) => state.app.notification
  );

  notification = null;

  constructor(
    private store: Store<{ app: IAppState }>,
    private authService: AuthService,
    private router: Router,
    private titleService: Title,
    private dataLayerService: DataLayerService,
    private userLogService: UserLogsService
  ) {
    this.router.events.subscribe(event=> { 
        if (event instanceof NavigationEnd)
        {
          this.dataLayerService.logPageView(event.url);
        }
    });
  }

  async ngOnInit() {
    this.authService.resetUserSubject.subscribe({
      next: async () => {
        const user  = await this.authService.getUser().pipe(take(1)).toPromise();
        LocalStorage.set(LOCAL_STORAGE_USER_KEY, user );
        this.store.dispatch(storeUserData({ user }))
      }
    })

    setInterval(() => {
      if(LocalStorage.get(LOCAL_STORAGE_USER_KEY)){
        this.authService.getUser().subscribe((user) => { }, (err) => {
          if(err.status === 401) {
            this.showAuthModal = true;
            const user = LocalStorage.get(LOCAL_STORAGE_USER_KEY);
            this.userLogService.sendLog(user.id, 'Seguridad', 'Cierre de sesión automatico por uso en otro dispositivo.');
            setTimeout(() => {
              window.location.replace('/usuarios/iniciar-sesion');
            }, 15000);
          }
        });
      }
    }, 180000) // 3 minutes

    this.store.dispatch(loadDolarBlue());
    this.store.dispatch(loadCalculationLinks());
    // Get
    this.titleService.setTitle('Sismat - El Portal de la Construccion')

    this.mercadopagoData$
    .subscribe((data) => {
      if(data && data['init_point']) {
        window.location.href = data['init_point'];
      }
    });

    this.notification$
    .subscribe((data) => {
      if(data) {
        this.notification = data;
        setTimeout(() => {
          this.notification = null;
          this.store.dispatch(resetNotification());
        }, 4000)
      }
    });

    this.store.dispatch(loadPlans());
    if(LocalStorage.get(LOCAL_STORAGE_USER_KEY)){
      await this.authService.getUser().subscribe(
        user => {
          LocalStorage.set(LOCAL_STORAGE_USER_KEY, user);
          this.store.dispatch(storeUserData({ user }));
        },
        error => {
          LocalStorage.remove(LOCAL_STORAGE_USER_KEY)
          this.userCheckReady = true;
        }, () => {
          this.userCheckReady = true;
        }
      )
    } else {
      this.userCheckReady = true
    }
  }

  showFooter() {
    return !this.urlsWithoutFooter.some(url => this.router.url.includes(url))
  }

  showNavbarOptions() {
    return !this.urlsWithoutNavbarOptions.some(url => this.router.url.includes(url))
  }
}
