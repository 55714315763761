import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LOCAL_STORAGE_USER_KEY } from '@app/utils/constants';
import { LocalStorage } from '@app/utils/local-storage/local-storage.utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuscriptionGuard implements CanActivate {

  constructor(private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const userData = LocalStorage.get(LOCAL_STORAGE_USER_KEY);
      if(!userData){
        // Not user - sent to suscribe
        this.router.navigate(['/suscribirse'])
        return false;
      } else if(!userData?.is_suscribed){
        // User but not suscribe - sent to suscribe for logged users
        this.router.navigate(['/suscribirse'])
        return false;
      } else {
        // Pass
        return true;
      }
  }

}
