<nav 
    *ngIf="!showNavbar(); else withoutNavbar" class="navbar is-fixed-top is-not-printable" role="navigation" aria-label="main navigation">
    <div class="container is-fluid">

        <div class="navbar-brand">
            <a class="navbar-item px-5" [routerLink]="['/']" (click)="resetMenu()">
                <img src="/assets/images/logo-sismat.png">
            </a>

            <a
                role="button"
                class="navbar-burger"
                [ngClass]="{'is-active': showMobileNavbar }"
                (click)="toggleMobileNavbar()"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
            >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>
        <div 
            id="navbarBasicExample" 
            class="navbar-menu" 
            [ngClass]="{'is-active': showMobileNavbar }" 
            *ngIf="!isMobile || showMobileNavbar"
            [@.disabled]="!isMobile">
            <div class="navbar-start">

                <div *ngIf="(isLogged$ | async) && isMobile" class="navbar-item has-dropdown" [ngClass]="{'is-active': selectedMenu === 'user'}">
                    <a class="navbar-link  is-arrowless" (click)="selectMenu('user')">
                        <span class="icon mr-1">
                            <i class="fas fa-building" *ngIf="!!(user$ | async)?.organization"></i>
                            <i class="fas fa-user" *ngIf="!(user$ | async)?.organization"></i>
                        </span>
                        {{ !!(user$ | async) ? !!(user$ | async)?.organization ? (user$ | async)?.organization?.name + ' - ' + (user$ | async).first_name : (user$ | async).email : '' }}
                    </a>
                    <div class="navbar-dropdown">
                        <a class="navbar-item" [routerLink]="['/usuarios', 'mi-perfil']"
                            routerLinkActive="router-link-active" (click)="resetMenu()">
                            {{ 'navbar__my-profile' | translate }}
                        </a>
                    </div>
                </div>

                <div class="navbar-item has-dropdown" [ngClass]="{'is-active': selectedMenu === 'costs'}">
                    <a class="navbar-link is-arrowless" (click)="selectMenu('costs')">
                        {{ 'navbar__costs' | translate }} 
                        <span class="icon" *ngIf="!isMobile">
                            <i 
                                class="fas fa-chevron-down"
                                [ngClass]="{'fa-rotate-180': selectedMenu === 'costs'}"
                            >
                            </i>
                        </span>
                    </a>
                    
                    <div class="navbar-dropdown">
                        <a class="navbar-item" [routerLink]="['/costos', 'materiales']"
                            routerLinkActive="router-link-active" (click)="resetMenu()">
                            {{ 'navbar__costs-materials' | translate }}
                        </a>
                        <a class="navbar-item" [routerLink]="['/costos', 'mano-de-obra']"
                            routerLinkActive="router-link-active" (click)="resetMenu()">
                            {{ 'navbar__costs-manpowers' | translate }}
                        </a>
                        <hr class="navbar-divider">
                        <a class="navbar-item" [routerLink]="['/costos', 'indices-de-costos']"
                            routerLinkActive="router-link-active" (click)="resetMenu()">
                            Índices de Costos <span class="navbar-new-tag">Nuevo</span>
                        </a>
                    </div>
                </div>

                <div class="navbar-item has-dropdown" [ngClass]="{'is-active': selectedMenu === 'compute'}">
                    <a class="navbar-link is-arrowless" (click)="selectMenu('compute')">
                        {{ 'navbar__compute' | translate }}
                        <span class="icon" *ngIf="!isMobile">
                            <i 
                                class="fas fa-chevron-down"
                                [ngClass]="{'fa-rotate-180': selectedMenu === 'compute'}"
                            >
                            </i>
                        </span>
                    </a>
                    <div class="navbar-dropdown">
                        <a class="navbar-item navbar__custom-compute" [routerLink]="['/computo']" (click)="resetMenu()">
                            <strong>{{ 'navbar__custom-compute' | translate }}</strong>
                        </a>
                        <hr class="navbar-divider">
                        <p class="navbar-label">Modelos de vivienda</p>
                        <ng-container *ngFor="let calculationlink of (calculationLink$ | async)">
                            <a *ngIf="!!calculationlink.show_in_home" class="navbar-item" [routerLink]="['/computo', calculationlink.id]" (click)="resetMenu()">
                                {{ calculationlink.name }} de {{ calculationlink.surface }} m²
                            </a>
                        </ng-container>
                        <hr class="navbar-divider">
                        <p class="navbar-label">Otros cómputos</p>
                        <ng-container *ngFor="let calculationlink of (calculationLink$ | async)">
                            <a *ngIf="!calculationlink.show_in_home" class="navbar-item" [routerLink]="!suscribedUser ? ['/computo', 'pre-establecido', calculationlink.id] : ['/computo', calculationlink.id]" (click)="resetMenu()">
                                {{ calculationlink.name }} de {{ calculationlink.surface }} m²
                            </a>
                        </ng-container>
                    </div>
                </div>


                <div class="navbar-item has-dropdown" [ngClass]="{'is-active': selectedMenu === 'tools'}">
                    <a class="navbar-link is-arrowless" (click)="selectMenu('tools')">
                        Herramientas
                        <span class="icon" *ngIf="!isMobile">
                            <i 
                                class="fas fa-chevron-down"
                                [ngClass]="{'fa-rotate-180': selectedMenu === 'tools'}"
                            >
                            </i>
                        </span>
                    </a>
                    
                    <div class="navbar-dropdown">
                        <a class="navbar-item" [routerLink]="['/costos', 'calculadora-de-materiales']"
                            routerLinkActive="router-link-active" (click)="resetMenu()">
                            {{ 'navbar__costs-materials-calculator' | translate }}
                        </a>
                        <a class="navbar-item" [routerLink]="['/detalles-constructivos']" routerLinkActive="router-link-active" (click)="resetMenu()">
                            {{ 'navbar__tech-details' | translate }}
                        </a>
                    </div>
                </div>
                <a class="navbar-item" target="_blank" href="https://www.instagram.com/sismat.com.ar/" (click)="resetMenu()">
                    <i class="fab fa-instagram"></i>
                </a>
                <a class="navbar-item" target="_blank" href="https://www.facebook.com/portalsismat" (click)="resetMenu()">
                    <i class="fab fa-facebook"></i>
                </a>
            </div>
            <div class="navbar-end">
                <ng-container *ngIf="(isLogged$ | async);else notLoggedActions">

                    <ng-container *ngIf="isMobile; else isDesktopProfiles">

                        <div class="navbar-item">
                            <div class="buttons">
                                <a 
                                    *ngIf="!(user$ | async)?.is_suscribed && !(isLoading$ | async) && !isPendingUser" 
                                    [routerLink]="['/suscribirse']" 
                                    class="button is-primary" 
                                >
                                    <span class="icon">
                                        <i class="fa fa-star"></i>
                                    </span>
                                    <span>{{ 'navbar__suscribe' | translate }}</span>
                                </a>
                                <a 
                                    *ngIf="!(isLoading$ | async) && !(user$ | async)?.organization_id"
                                    class="button is-light" 
                                    (click)="logout()"
                                >
                                    <span class="icon">
                                        <i class="fa fa-sign-in-alt"></i>
                                    </span>
                                    <span>{{ 'navbar__logout' | translate }}</span>
                                </a>
                            </div>
                        </div>


                    </ng-container>
                    <ng-template #isDesktopProfiles>

                        <div class="navbar-item has-dropdown" [ngClass]="{'is-active': selectedMenu === 'user'}">
                            <a class="navbar-link" (click)="selectMenu('user')" >
                                <span class="icon mr-1">
                                    <i class="fas fa-building" *ngIf="!!(user$ | async)?.organization"></i>
                                    <i class="fas fa-user" *ngIf="!(user$ | async)?.organization"></i>
                                </span>
                                <!--{{ 'navbar__hello' | translate }} -->
                                {{ !!(user$ | async) ? !!(user$ | async)?.organization ? (user$ | async)?.organization?.name + ' - ' + (user$ | async).first_name : (user$ | async)?.email : '' }}
                            </a>
                            <div class="navbar-dropdown">
                                <a class="navbar-item" [routerLink]="['/usuarios', 'mi-perfil']"
                                    routerLinkActive="router-link-active" (click)="resetMenu()">
                                    {{ 'navbar__my-profile' | translate }}
                                </a>
                                <a *ngIf="!(user$ | async)?.organization" class="navbar-item" [routerLink]="['/usuarios', 'preferencias']"
                                    routerLinkActive="router-link-active" (click)="resetMenu()">
                                    Editar datos
                                </a>
                            </div>
                        </div>

                        <div class="navbar-item">
                            <div class="buttons">
                                <a 
                                    *ngIf="!(user$ | async)?.is_suscribed && !(isLoading$ | async) && !isPendingUser" 
                                    [routerLink]="['/suscribirse']" 
                                    class="button is-primary"
                                >
                                    <span class="icon">
                                        <i class="fa fa-star"></i>
                                    </span>
                                    <span>{{ 'navbar__suscribe' | translate }}</span>
                                </a>
                                <a class="button is-light" *ngIf="!(user$ | async)?.organization_id" (click)="logout()">{{ 'navbar__logout' | translate }}
                                </a>
                            </div>
                        </div>

                    </ng-template>

                </ng-container>
                <ng-template #notLoggedActions>
                    <div class="navbar-item" *ngIf="!(isLoading$ | async)">
                        <div class="buttons">
                            <a 
                                class="button is-primary " 
                                *ngIf="!isPendingUser"
                                [routerLink]="['/suscribirse']" 
                                routerLinkActive="is-light"
                                [routerLinkActiveOptions]="{ exact: true }" 
                                (click)="resetMenu()"
                            >
                                <span class="icon">
                                    <i class="fa fa-star"></i>
                                </span>
                                <span>{{ 'navbar__suscribe' | translate }}</span>
                            </a>
                            <a 
                                class="button is-primary is-light" 
                                [routerLink]="['/usuarios','iniciar-sesion']" 
                                (click)="resetMenu()"
                            >
                                <span class="icon">
                                    <i class="fa fa-sign-in-alt"></i>
                                </span>
                                <span>{{ 'navbar__login' | translate }}</span>
                            </a>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</nav>
<div 
    *ngIf="showDueWarning && viewCanShowDueWarning" 
    class="due-warning has-background-danger  is-not-printable"
    [ngClass]="{ 'due-warning--home': isHomepage && !isMobile }"
>
        <ng-container *ngIf="daysDiff > 0; else lessThanOneDay">
            <span *ngIf="!isMobile">Solo te</span> 
            <span *ngIf="isMobile">Te</span> 
            quedan {{ daysDiff | number: '1.0-0'}} día{{ daysDiff >= 2 ? 's' : '' }} de suscripción. 
        </ng-container>
        <ng-template #lessThanOneDay>
            <span>Tu suscripción se encuentra vencida</span>
        </ng-template>
        <i class="fas fa-xs fa-sync mr-1 ml-3"></i>
        <a class="due-warning__link" [routerLink]="['/renovar-suscripcion']">Renovar<span *ngIf="!isMobile"> suscripción</span></a>
        <i class="due-warning__icon fas fa-times" (click)="showDueWarning = false"></i>
</div>
<ng-template #withoutNavbar>
    <nav class="navbar navbar--no-fixed-top is-not-printable" role="navigation" aria-label="main navigation">
        <div class="container is-fluid">
    
            <div class="navbar-brand">
                <a class="navbar-item px-5" [routerLink]="['/']">
                    <img src="/assets/images/logo-sismat.png">
                </a>
            </div>
            <div class="navbar-end" *ngIf="isMarketing && !isMobile">
                <div class="navbar-item">
                    <div class="buttons">
                        <a 
                            class="button is-primary is-light" 
                            [routerLink]="['/usuarios','iniciar-sesion']" 
                            (click)="resetMenu()"
                        >
                            <span class="icon">
                                <i class="fa fa-sign-in-alt"></i>
                            </span>
                            <span>{{ 'navbar__login' | translate }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        
    </nav>
</ng-template>
<progress class="progress is-primary navbar__loading" max="100" *ngIf="(isLoading$ | async)"></progress>


