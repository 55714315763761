import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IComputeStage } from '@app/models/IComputeStage';
import { IStage } from '@app/models/IStage';
import { Observable } from 'rxjs';
import { BaseService } from '../base/base.service';

@Injectable({
    providedIn: 'root'
})
export class ComputeService extends BaseService {
    prefixPath = 'calculations';
    linksPrefixPath = 'calculationlinks';
    materialPrefixPath = 'get-materials-from-calculation';

    constructor(httpClient: HttpClient) {
      super(httpClient);
    }

    getMaterials(tasks): Observable<any> {
      return this.httpPost(`${this.materialPrefixPath}`, tasks)
    }

    getCalculation(id): Observable<any> {
      return this.httpGet(`${this.prefixPath}/${id}`);
    }

    getCalculationLinks(): Observable<any> {
      return this.httpGet(`${this.linksPrefixPath}`);
    }
}