import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyService {

  constructor(private http: HttpClient) { }

  getDolarBlue() {
    //return this.http.get('https://api.bluelytics.com.ar/v2/latest');
    return of({
      "oficial": {
        "value_avg": 148.00,
        "value_sell": 152.00,
        "value_buy": 144.00
      },
      "blue": {
        "value_avg": 283.00,
        "value_sell": 285.00,
        "value_buy": 281.00
      },
      "oficial_euro": {
        "value_avg": 159.00,
        "value_sell": 163.00,
        "value_buy": 155.00
      },
      "blue_euro": {
        "value_avg": 304.00,
        "value_sell": 306.00,
        "value_buy": 302.00
      },
      "last_update": "2022-09-22T15:40:48.892738-03:00"
    })
  }
}
