<ng-container>
    <app-navbar></app-navbar>
    <article class="message notification is-not-printable" [ngClass]="'is-' + notification?.type" *ngIf="notification" [@inOutAnimation]>
        <div class="message-body">
            <div class="columns">
                <div class="column is-narrow is-hidden-mobile">
                    <i *ngIf="notification?.type === 'success'" class="fa fa-check fa-2x"></i>
                    <i *ngIf="notification?.type === 'warning'" class="fa fa-exclamation-triangle fa-2x"></i>
                    <i *ngIf="notification?.type === 'danger'" class="fa fa-times fa-2x"></i>
                    <i *ngIf="notification?.type === 'info'" class="fa fa-info-circle fa-2x"></i>
                </div>
                <div class="column">
                    <p class="h4 hb mb-2">{{ notification?.title }}</p>
                    <p>{{ notification?.text }}</p>
                </div>
            </div>
        </div>
    </article>
    <div class="app">
        <router-outlet></router-outlet>
    </div>
    <app-footer *ngIf="showFooter()"></app-footer>
</ng-container>
<!-- DEPRECATED
<div class="loading" *ngIf="!userCheckReady" [@onlyOutAnimation]>
    <img src="/assets/images/logo-sismat-inv.png" class="loading__image mb-5" alt="Sismat">
    <p><i class="fa fa-spinner fa-spin fa-3x"></i></p>
</div>-->


<div class="modal is-active" *ngIf="showAuthModal">
    <div class="modal-background"></div>
    <div class="modal-card">
        <section class="modal-card-body auth-modal__body" style="background-image: url('/assets/images/auth-modal.jpeg')">


        <div class="content">
          <h3 class="h3 hb auth-modal__title">Detectamos actividad desde otro dispositivo</h3>
            <p>
                Solo podes usar Sismat desde un dispositivo a la vez. En unos segundos se cerrará la sesión.
            </p>
            <article class="message is-warning mt-2
            Si no reconocés está acción te recomendamos que cambies tu contraseña desde tu panel de usuario.">
                <div class="message-body">
                    <div class="columns">
                        <div class="column is-narrow is-hidden-mobile">
                            <i class="fa fa-exclamation-triangle fa-2x"></i>
                        </div>
                        <div class="column">
                            Si no reconocés está acción te recomendamos que cambies tu contraseña desde tu panel de usuario.
                        </div>
                    </div>
                </div>
            </article>
        </div>
      </section>
    </div>
</div>