import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';
import { SuscriptionGuard } from './guards/suscription/suscription.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren:
    () => import('@app/modules/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: 'computo',
    loadChildren:
    () => import('@app/modules/compute/compute.module').then((m) => m.ComputeModule)
  },
  {
    path: 'usuarios',
    loadChildren:
    () => import('@app/modules/users/users.module').then((m) => m.UsersModule)
  },
  {
    path: 'costos',
    loadChildren:
    () => import('@app/modules/costs/costs.module').then((m) => m.CostsModule),
    canActivate: [SuscriptionGuard]
  },
  {
    path: 'detalles-constructivos',
    loadChildren:
    () => import('@app/modules/tech-details/tech-details.module').then((m) => m.TechDetailsModule),
    canActivate: [SuscriptionGuard]
  },{
    path: '**',
    component: NotFoundComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
