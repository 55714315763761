import { ICalculationLink } from '@app/models/ICalculationLink';
import { IError } from '@app/models/IError';
import { INotification } from '@app/models/INotification';
import { IPlan } from '@app/models/IPlan';
import { IUserCalculationSummary } from '@app/models/IUserCalculationSummary';
import { createReducer, createSelector, on } from '@ngrx/store';
import * as actions from '../../actions/app/app.actions';

export const reducerKey = 'app';

export interface IAppState {
  isLoading: boolean;
  isLogged: boolean;
  plans: IPlan[];
  calculationlinks: ICalculationLink[],
  usercalculations: IUserCalculationSummary[],
  user: any;
  mercadopagoData: any;
  notification: INotification;
  error: IError;
  dolarBlue: number;
}

export const initialState: IAppState = {
  isLoading: false,
  isLogged: false,
  plans: [],
  calculationlinks: [],
  usercalculations: [],
  user: null,
  mercadopagoData: null,
  notification: null,
  error: null,
  dolarBlue: null
};

export const reducer = createReducer(
  initialState,
  on(actions.startLoading, (state) => ({ ...state, isLoading: true })),
  on(actions.stopLoading, (state) => ({ ...state, isLoading: false })),
  on(actions.storeUserData, (state, { user }) => ({ ...state, user, isLogged: true })),
  on(actions.removeUserData, (state) => ({ ...state, user: null, isLogged: false })),
  on(actions.loadPlansSuccess, (state, { plans }) => ({
    ...state,
    plans
  })),
  on(actions.loadDolarBlueSuccess, (state, { dolarBlue }) => ({
    ...state,
    dolarBlue
  })),
  on(actions.loadCalculationLinksSuccess, (state, { calculationlinks }) => ({
    ...state,
    calculationlinks
  })),
  on(actions.loadUserCalculationsSuccess, (state, { usercalculations }) => ({
    ...state,
    usercalculations
  })),
  on(actions.getPreferenceIdPostRegistrationSuccess, (state, { mercadopagoData }) => ({
    ...state,
    mercadopagoData
  })),
  on(actions.setNotification, (state, { notification }) => ({
    ...state,
    notification
  })),
  on(actions.resetNotification, (state) => ({
    ...state,
    notification: null
  })),
  on(actions.setError, (state, { error }) => ({
    ...state,
    error
  })),
  on(actions.resetError, (state) => ({
    ...state,
    error: null
  }))
);

export const isLoadingSelector = createSelector(
  (state: { app: IAppState } ) => state.app,
  (state: IAppState) => state.isLoading
);