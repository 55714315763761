import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class PendingUserService {

    public pendingUserSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() { }
    
}