import { animate, style, transition, trigger } from '@angular/animations';

export const computeSideNavAnimation = [
    transition(':enter',[
      style({ opacity : 0, height: '0' }),
      animate('0.5s ease-out', style({ opacity : 1, height: '200px' }))
    ]),
    transition(':leave',[
      style({ opacity : 1, height: '200px' }),
      animate('0.5s ease-in', style({ opacity : 0, height: '0' }))
    ]),
];

export const inOutAnimation = [
  transition(':enter',[
    style({ opacity : 0 }),
    animate('.5s ease-out', style({ opacity : 1 }))
  ]),
  transition(':leave',[
    style({ opacity : 1 }),
    animate('.5s ease-in', style({ opacity : 0 }))
  ]),
];

export const navbarAnimation = [
    transition(':enter',[
      style({ height: '0', opacity: 0}),
      animate('0.5s ease-out', style({height: 'calc(100vh - 50px)', opacity: 1 }))
    ]),
    transition(':leave',[
      style({ height: 'calc(100vh - 50px)', opacity: 1 }),
      animate('0.005s ease-in', style({height: '0' , opacity: 0}))
    ]),
];

export const onlyOutAnimation = [
    transition(':leave',[
      style({ opacity : 1 }),
      animate('.5s ease-in', style({ opacity : 0 }))
    ]),
];
