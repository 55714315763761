import { Injectable } from "@angular/core";
import * as appActions from "@app/store/actions/app/app.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { mergeMap } from 'rxjs/operators';
import { Store } from "@ngrx/store";
import { PlansService } from "@app/services/plans/plans.service";
import { MercadopagoService } from "@app/services/mercadopago/mercadopago.service";
import { UserCalculationsService } from "@app/services/usercalculations/usercalculations.service";
import { ComputeService } from "@app/services/compute/compute.service";
import { ThirdPartyService } from "@app/services/third-party/third-party.service";

@Injectable()
export class AppEffects {
    loadPlan$ = createEffect(() => this.actions$.pipe(
        ofType(appActions.loadPlans),
        mergeMap(() => {

            this.store.dispatch(appActions.startLoading());
            return this.plansService.getData().pipe(
                mergeMap((plans) => [
                    appActions.loadPlansSuccess({ plans }),
                    appActions.stopLoading()
                ])
            );
        })
    ));

    loadDolarBlue$ = createEffect(() => this.actions$.pipe(
        ofType(appActions.loadDolarBlue),
        mergeMap(() => {

            this.store.dispatch(appActions.startLoading());
            return this.thirdPartyService.getDolarBlue().pipe(
                mergeMap((response) => [
                    appActions.loadDolarBlueSuccess({ dolarBlue: response['blue']['value_sell'] }),
                    appActions.stopLoading()
                ])
            );
        })
    ));

    loadCalculationLink$ = createEffect(() => this.actions$.pipe(
        ofType(appActions.loadCalculationLinks),
        mergeMap(() => {
            this.store.dispatch(appActions.startLoading());
            return this.computeService.getCalculationLinks().pipe(
                mergeMap((calculationlinks) => [
                    appActions.loadCalculationLinksSuccess({ calculationlinks }),
                    appActions.stopLoading()
                ])
            );
        })
    ));

    loadPreferenceId$ = createEffect(() => this.actions$.pipe(
        ofType(appActions.getPreferenceIdPostRegistration),
        mergeMap(({ userId, planId }) => {

            this.store.dispatch(appActions.startLoading());
            return this.mercadopagoService.getPreferenceId(userId, planId).pipe(
                mergeMap((mercadopagoData) => [
                    appActions.getPreferenceIdPostRegistrationSuccess({ mercadopagoData }),
                    appActions.stopLoading()
                ])
            );
        })
    ));

    loadUserCalculation$ = createEffect(() => this.actions$.pipe(
        ofType(appActions.loadUserCalculations),
        mergeMap(({ userId }) => {

            this.store.dispatch(appActions.startLoading());
            return this.userCalculationsService.getUserCalculations(userId).pipe(
                mergeMap((usercalculations) => [
                    appActions.loadUserCalculationsSuccess({ usercalculations }),
                    appActions.stopLoading()
                ])
            );
        })
    ));

    constructor(
        private actions$: Actions,
        private store: Store,
        private plansService: PlansService,
        private userCalculationsService: UserCalculationsService,
        private computeService: ComputeService,
        private thirdPartyService: ThirdPartyService,
        private mercadopagoService: MercadopagoService
    ) { }
}
